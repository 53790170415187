const search = function()  {
    const formSearchBox = document.getElementById('searchBox');
    const searchTerm = document.getElementById('searchTerm');
    const resultSearchContainer = document.getElementById('resultSearchContainer')
    const boxSearchResultList = document.getElementById('boxSearchResultList')
    const labelAllResultBtn = 'Ver todos Resultados'

    const hideBoxSearchResult = function ()
    {
        while (boxSearchResultList.firstChild) {
            boxSearchResultList.removeChild(boxSearchResultList.lastChild);
        }
    }
    const searchBox = function() {

        let term = searchTerm.value;
        let ajaxReq = false;

        if (ajaxReq)
        {
            ajaxReq.abort();
        }

        if(term.length < 2){
            resultSearchContainer.style.display = 'none';
            return;
        }
        resultSearchContainer.style.display = 'block';

        const url = formSearchBox.getAttribute('action');
        let data = {
            searchTerm: searchTerm.value,
            searchType: 'onTyping'
        };

        ajaxReq = $.ajax({
            url: url,
            data: data,
            dataType: 'json',
            type: 'get',
            success: function (response) {
                if (response.success == true)
                {
                    let htmlHost = window.location.origin + '/'
                    let boxSearchResultListItems =  boxSearchResultList.childNodes;
                    hideBoxSearchResult()

                    for (cont = 0; cont < response.products.length; cont++)
                    {
                        let htmlResult = '';
                        let nodeLi = document.createElement("LI")

                        htmlResult +=
                            '<a href="' + response.products[cont].product_link + '">' +
                            '<figure>';

                        if (response.products[cont].imagem_produto)
                        {
                            htmlResult += '<img src="' + htmlHost + 'public/' + response.products[cont].imagem_produto + '" width="70" style="width: auto; height: 70px;">';
                        }
                        else
                        {
                            htmlResult += '<img src="' + htmlHost + 'public/' + 'images/not-found/70-70/70-70.jpg">';
                        }
                        htmlResult += '</figure>' +
                            '<span>' +
                            '<h6>' + response.products[cont].referencia_principal_site + '</h6>' +
                            '<small>' + response.products[cont].nome_idioma + '</small>' +
                            '</span>' +
                            '</a>';

                        nodeLi.innerHTML = htmlResult;

                        boxSearchResultList.appendChild(nodeLi)
                        htmlResult= '';
                    }

                    let nodeLiFinal = document.createElement("LI")
                    let searchTermGet = document.getElementById('searchTerm');
                    let seeAllItems =
                        '<a href="' + APP_URL + '/busca?searchType=bladeSearch&searchTerm='+ searchTermGet.value +'" class="btnShowResultadosBusca">' +
                            '<span style="text-align: center; display: inline-block; width:100%">' +
                            '<h6>' + labelAllResultBtn + '</h6>'+
                            '</span>' +
                        '</a>';
                    nodeLiFinal.innerHTML = seeAllItems
                    boxSearchResultList.appendChild(nodeLiFinal)

                }
            },
            error: function (response, error) {
                hideBoxSearchResult()
            }
        })

    }
    return {
        init: () => {
            searchTerm.addEventListener('keyup', function (event) {
                searchBox()
            })
        }
    }
}();

$(document).ready(function()
{
    search.init();
});